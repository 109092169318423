import {Controller} from '@hotwired/stimulus';
import {IntersectionController, useIntersection} from "stimulus-use";
import gsap from "gsap";

export default class extends Controller {
  options = {
    threshold: 0.25, // 0 by default
  };
  connect() {
    const [observeIntersection, unobserveIntersection] = useIntersection(
        this,
        this.options
    );
    gsap.set(this.element, {
      opacity: 0,
    });

    document.querySelector("body").overflowX = "hidden";

    if (this.element.classList.contains("anim-right")) {
      gsap.set(this.element, {
        x: 25,
      });
    }
    if (this.element.classList.contains("anim-left")) {
      gsap.set(this.element, {
        x: -25,
      });
    }
    if (this.element.classList.contains("anim-vertical")) {
      gsap.set(this.element, {
        y: 25,
      });
    }
    if (this.element.classList.contains("anim-vertical-top")) {
      gsap.set(this.element, {
        y: -25,
      });
    }
  }

  appear(entry) {
    gsap.to(this.element, {
      opacity: 1,
      x: 0,
      y: 0,
      duration: 1,
      stagger: 0.2,
    });

    // console.log(`appear`, this);
    // callback automatically triggered when the element
    // intersects with the viewport (or root Element specified in the options)
  }

  disappear(entry) {
    //console.log(`disappear`);
    // callback automatically triggered when the element
    // leaves the viewport (or root Element specified in the options)
  }
}
