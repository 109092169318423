import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class extends Controller {
  connect() {
    gsap.registerPlugin(ScrollTrigger);

    //? DESKTOP
    if (document.body.offsetWidth > 1024) {
      this.element.style.objectPosition = "50% 60%";

      gsap.to(this.element, {
        objectPosition: `50% 0%`,
        ease: "none",

        scrollTrigger: {
          toggleActions: "play pause reverse pause",
          start: "top top",
          end: "bottom 50%",
          // markers: true,
          scrub: true,
        },
      });

      //? MOBIlE
    } else {
      if (this.element.classList.contains("less-img-parallax")) {
        //? Less parallax
        // this.element.style.objectFit = "none";
        this.element.style.objectPosition = "50% 50%";

        gsap.to(this.element, {
          objectPosition: `50% 100%`,
          ease: "none",

          scrollTrigger: {
            toggleActions: "play pause reverse pause",
            start: "top top",
            end: "bottom 50%",
            // markers: true,
            scrub: true,
          },
        });
      } else {
        //? Full parallax
        // this.element.style.objectFit = "none";
        this.element.style.objectPosition = "50% 100%";

        gsap.to(this.element, {
          objectPosition: `50% 0%`,
          ease: "none",

          scrollTrigger: {
            toggleActions: "play pause reverse pause",
            start: "top top",
            end: "bottom 50%",
            // markers: true,
            scrub: true,
          },
        });
      }
    }
  }
}
